import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import { getLoan, readLoan, updateLoanData } from "../api/loan";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  validateMax,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { customers } from "../api/customer";
function Loan() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [agreementDB, setAgreementDB] = useState("");
  const [instantLoanTenure, setInstantLoanTenure] = useState([]);

  function onChangeData(e) {
    if (
      data.loan_type === "Instant" &&
      e.target.name === "selectedInstantTenure"
    ) {
      let selectedTenure = e.target.value;
      if (selectedTenure === "7 days") {
        setData((data) => ({
          ...data,
          offered_tenure: "7",
          offered_tenure_in: "days",
        }));
      } else if (selectedTenure === "15 days") {
        setData((data) => ({
          ...data,
          offered_tenure: "15",
          offered_tenure_in: "days",
        }));
      } else if (selectedTenure === "3 months") {
        setData((data) => ({
          ...data,
          offered_tenure: "3",
          offered_tenure_in: "months",
        }));
      } else if (selectedTenure === "6 months") {
        setData((data) => ({
          ...data,
          offered_tenure: "6",
          offered_tenure_in: "months",
        }));
      } else if (selectedTenure === "1 year") {
        setData((data) => ({
          ...data,
          offered_tenure: "1",
          offered_tenure_in: "years",
        }));
      } else if (selectedTenure === "2 years") {
        setData((data) => ({
          ...data,
          offered_tenure: "2",
          offered_tenure_in: "years",
        }));
      }
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  // const setEMIAmount = () => {
  //     // if(data.offered_amount && data.)
  // }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            const res = await getLoan({
              loan_id: id,
              token: response.data.accessToken,
            });
            if (res?.status === 200) {
              res.data.emi = await get_emi();
              res.data.status_name = getValue(res.data.status, status)
              res.data.bank_name_name = res.data.bank_name ? getValue(res.data.bank_name, bankNames) : null
              res.data.loan_type_name = getValue(res.data.loan_type, loanTypes)
              setData(res.data);
              setAgreementDB(response.data.agreement);
            }
            if (!data.read_time || !data.admin_id) {
              const res1 = await readLoan({
                token: response.data.accessToken,
                data: {
                  id: id,
                  admin_id: response.data.id,
                },
              });
            }
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const offered_interest = data.offered_interest;
    const offered_tenure_in = data.offered_tenure_in;
    const offered_tenure = data.offered_tenure;
    const offered_amount = data.offered_amount;

    if (
      offered_amount &&
      offered_interest &&
      offered_tenure &&
      offered_tenure_in
    ) {
      const monthlyInterestRate = offered_interest
        ? offered_interest / 12 / 100
        : 0;
      const totalPayments =
        offered_tenure_in === "months" ? offered_tenure : offered_tenure * 12;

      if (monthlyInterestRate === 0) {
        const EMI = offered_amount / totalPayments;
        setData((data) => ({
          ...data,
          total_payable_amount: Number(offered_amount).toFixed(2),
          emi_amount: Number(EMI).toFixed(2),
        }));
      } else {
        const EMI =
          (offered_amount *
            monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, totalPayments)) /
          (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
        // setEmi(EMI);

        // let scheduleHTML = '<table>';
        // scheduleHTML += '<tr><th>Month</th><th>Payment</th><th>Principal</th><th>Interest</th><th>Balance</th></tr>';

        let balance = offered_amount;
        let totalInterest = 0;
        for (let month = 1; month <= totalPayments; month++) {
          const interest = balance * monthlyInterestRate;
          totalInterest += interest;
          const principal = EMI - interest;
          balance -= principal;
          //     scheduleHTML += `<tr><td>${month}</td><td>${EMI.toFixed(2)}</td><td>${principal.toFixed(2)}</td><td>${interest.toFixed(2)}</td><td>${balance.toFixed(2)}</td></tr>`;
        }
        // scheduleHTML += '</table>';
        // setSchedule(scheduleHTML)
        // setTotalInterestAmount(totalInterest)
        const t =
          Number(totalInterest) + (offered_amount ? Number(offered_amount) : 0);
        setData((data) => ({
          ...data,
          total_payable_amount: t.toFixed(2),
          emi_amount: EMI.toFixed(2),
        }));
      }
    }
  }, [
    data.offered_amount,
    data.offered_tenure,
    data.offered_interest,
    data.offered_tenure_in,
  ]);

  function validateLoanType(str) {
    setErrors((errors) => ({
      ...errors,
      loan_type: validateSelect(str).error,
    }));
  }
  function validateBankName(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateSelect(str).error,
    }));
  }
  function validateLoanStatus(str) {
    setErrors((errors) => ({
      ...errors,
      status: validateSelect(str).error,
    }));
  }
  function validateOfferedAmount(str) {
    setErrors((errors) => ({
      ...errors,
      offered_amount: validateSalary(str).error,
    }));
  }
  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }
  function validateOfferedTenure(str) {
    setErrors((errors) => ({
      ...errors,
      offered_tenure: validateSalary(str).error,
    }));
  }
  function validateOfferedTenureIn(str) {
    setErrors((errors) => ({
      ...errors,
      offered_tenure_in: validateSelect(str).error,
    }));
  }
  function validateOfferedInterest(str) {
    setErrors((errors) => ({
      ...errors,
      offered_interest: validateSalary(str).error,
    }));
  }
  function validateTotalPayable(offered_amount, total_payable_amount) {
    setErrors((errors) => ({
      ...errors,
      total_payable_amount: validateMax(offered_amount, total_payable_amount)
        .error,
    }));
  }
  function validateProcessingFee(str) {
    setErrors((errors) => ({
      ...errors,
      processing_fee: validateSalary(str).error,
    }));
  }

  function validateAddForm() {
    validateLoanType(data.loan_type);
    validateLoanStatus(data.status);
    validateOfferedAmount(data.offered_amount);
    validateOfferedTenure(data.offered_tenure);
    validateOfferedTenureIn(data.offered_tenure_in);
    validateOfferedInterest(data.offered_interest);
    validateTotalPayable(data.offered_amount, data.total_payable_amount);
    validateProcessingFee(data.processing_fee);
    if (!id) {
      validateCustomer();
    }
    if (data.loan_type === "InstantFromBank"){
      validateBankName(data.bank_name);
    }

    let valid =
      validateSelect(data.loan_type).valid &&
      validateSelect(data.status).valid &&
      validateSalary(data.offered_amount).valid &&
      validateSalary(data.offered_tenure).valid &&
      validateSelect(data.offered_tenure_in).valid &&
      validateSalary(data.offered_interest).valid &&
      validateMax(data.offered_amount, data.total_payable_amount).valid &&
      validateSalary(data.processing_fee).valid &&
      !id
        ? validateSelect(selectedCust).valid
        : true &&
      data.loan_type === "InstantFromBank"
        ? validateSelect(data.bank_name).valid 
        : true;
    return valid;
  }

  const get_emi = () => {
    let emi_data;
    if (data.loan_type !== "Instant") {
      emi_data = 1;
    } else {
      if (data?.offered_amount && data?.offered_amount < 10000) {
        emi_data = 0;
      } else {
        emi_data = data?.emi ? data.emi : 0;
      }
    }
    return emi_data;
  };

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      const formData = new FormData();
      formData.append("id", id ? id : null);
      formData.append(
        "admin_id",
        adminData.id || getLocalStorage("rv-admin")?.id
      );
      formData.append(
        "customer_id",
        selectedCust ? selectedCust : data.customer_id
      );
      formData.append("loan_type", data.loan_type);
      formData.append("bank_name", data.bank_name);
      formData.append("offered_amount", data.offered_amount);
      formData.append("offered_interest", data.offered_interest);
      formData.append("offered_tenure", data.offered_tenure);
      formData.append("offered_tenure_in", data.offered_tenure_in);
      formData.append("processing_fee", data.processing_fee);
      formData.append("total_payable_amount", data.total_payable_amount);
      formData.append("emi", await get_emi());
      formData.append("emi_amount", data.emi_amount);
      formData.append(
        "status",
        data.status === "RequestByClient" ? "CounterOffer" : data.status
      );
      const response = await updateLoanData({
        data: formData,
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/loan?id=${id}`);
        } else {
          navigate("/loans");
        }
      } else {
        Swal.fire("Oops!", "Something went wrong...", "error");
      }
    }
  };

  const status = [
    {
      id: "RequestedByClient",
      name: "Requested By Client",
    },
    {
      id: "Reviewed",
      name: "Reviewed",
    },
    {
      id: "CounterOffer",
      name: "Given Counter Offer",
    },
    {
      id: "ResponseByClient",
      name: "Responsed By Client",
    },
    {
      id: "CustomerKYC",
      name: "Customer KYC Data",
    },
    {
      id: "CustomerAddress",
      name: "Customer Address Data",
    },
    {
      id: "CustomerBank",
      name: "Customer Bank Data",
    },
    {
      id: "LoanReference",
      name: "Reference Data for Loan",
    },
    {
      id: "Granted",
      name: "Disbursal",
    },
    {
      id: "Cancelled",
      name: "Cancelled",
    },
  ];
  const bankNames = [{
      id: "IndusindPersonal",
      name: "Indusind Personal Loan"
    },{
      id: "AdityaBirlaPersonal",
      name: "Aditya BirlaPersonal Loan"
    },{
      id: "SMFCPersonal",
      name: "SMFC Personal Loan"
    },{
      id: "CreditVidyaPersonal",
      name: "Credit Vidya Personal Loan"
    },{
      id: "FibePersonal",
      name: "Fibe Personal Loan"
    },{
      id: "IIFLBusiness",
      name: "IIFL Business Loan"
    },{
      id: "BajajPersonal",
      name: "Bajaj Personal Loan"
    },{
      id: "BirlaUdyogBusiness",
      name: "Aditya Birla Udyog Business Loan"
    }
  ]
  const loanTypes = [
    // {
    //   id: "Instant",
    //   name: "Instant",
    // },
    {
      id: "InstantFromBank",
      name: "Instant Loan From Bank",
    },
    {
      id: "Business",
      name: "Business Loan",
    },
    {
      id: "Education",
      name: "Education Loan",
    },
    {
      id: "Machinary",
      name: "Machinary Loan",
    },
    {
      id: "Home",
      name: "Home Laon",
    },
    {
      id: "AgainstProperty",
      name: "Loan Against Property",
    },
    {
      id: "Professional",
      name: "Professional Loan",
    },
    {
      id: "Car",
      name: "Car Loan",
    },
    {
      id: "Student",
      name: "Student Loan",
    },
  ];

  const getValue = (id, array) => {
    console.log("data==", id, array);
    
    const value = array.find(data => data.id === id);
    return value.name;
  }

  useEffect(() => {
    if (data.loan_type === "Instant") {
      let amount = data.offered_amount;
      let t = [];
      if (amount < 5000) {
        t = ["7 days", "15 days"];
      } else if (amount < 10000) {
        t = ["15 days", "3 months"];
      } else if (amount < 25000) {
        t = ["3 months", "6 months", "1 year"];
      } else {
        t = ["6 months", "1 year", "2 years"];
      }
      setInstantLoanTenure(t);
    }
  }, [data.loan_type, data.offered_amount]);

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/loans">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link className="text-decoration-none" to={`/loan?id=${id}&edit=1`}>
              <Button className="primary-btn" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                {!(!id && edit) && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}{" "}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!id && edit && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Customer</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <Autocomplete
                        value={
                          findOptionById(customersData, selectedCust) || null
                        } // Ensure value is not null
                        onChange={(event, newValue) => {
                          setSelectedCust(newValue ? newValue.id : null);
                        }}
                        onBlur={() => validateCustomer(selectedCust)}
                        options={
                          !customersData
                            ? [{ label: "Loading...", id: 0 }]
                            : customersData
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Loan Type</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit && !id ? (
                      <Select
                        value={data.loan_type}
                        name="loan_type"
                        onChange={onChangeData}
                        onBlur={() => validateLoanType(data.loan_type)}
                      >
                        {loanTypes.map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{data.loan_type_name}</b>
                    )}
                    {errors.loan_type && edit && (
                      <Typography className="error-text">
                        {errors.loan_type}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                {data.loan_type === "InstantFromBank" && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Bank Name</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      {edit && !id ? (
                        <Select
                          value={data.bank_name}
                          name="bank_name"
                          onChange={onChangeData}
                          onBlur={() => validateBankName(data.bank_name)}
                        >
                          {bankNames.map((option, i) => (
                            <MenuItem key={i} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <b>{data.bank_name_name}</b>
                      )}
                      {errors.bank_name && edit && (
                        <Typography className="error-text">
                          {errors.bank_name}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Loan Status</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <Select
                        value={data.status}
                        name="status"
                        onChange={onChangeData}
                        onBlur={() => validateLoanStatus(data.status)}
                      >
                        {status.map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{data.status_name}</b>
                    )}
                    {errors.status && edit && (
                      <Typography className="error-text">
                        {errors.status}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </Table>
              {id && data.loan_type === "Instant" ? (
                <>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel className="text">Offered Amount</InputLabel>
                      {edit ? (
                        <TextField
                          name="offered_amount"
                          value={data.offered_amount}
                          onChange={onChangeData}
                          onBlur={() =>
                            validateOfferedAmount(data.offered_amount)
                          }
                        />
                      ) : (
                        <b>{data.offered_amount}</b>
                      )}
                      {errors.offered_amount && edit && (
                        <Typography className="error-text">
                          {errors.offered_amount}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel className="text">Requested Amount</InputLabel>
                      {data.req_amount}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel className="text">Offered Tenure</InputLabel>
                      {edit ? (
                        <>
                          <Select
                            name="selectedInstantTenure"
                            onChange={onChangeData}
                          >
                            {instantLoanTenure.map((option, i) => (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <b>
                          {data.offered_tenure} {data.offered_tenure_in}
                        </b>
                      )}

                      {errors.offered_tenure && edit && (
                        <Typography className="error-text">
                          {errors.offered_tenure}
                        </Typography>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                                            <InputLabel className="text">Requested Tenure</InputLabel>
                                            <>{data.req_tenure} {data.req_tenure_in}</>
                                        </Grid> */}
                  </Grid>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel className="text">Offered Interest</InputLabel>
                      {edit ? (
                        <TextField
                          name="offered_interest"
                          value={data.offered_interest}
                          onChange={onChangeData}
                          onBlur={() =>
                            validateOfferedInterest(data.offered_interest)
                          }
                        />
                      ) : (
                        <b>{data.offered_interest}</b>
                      )}
                      {errors.offered_interest && edit && (
                        <Typography className="error-text">
                          {errors.offered_interest}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Table>
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Amount</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit ? (
                          <TextField
                            name="offered_amount"
                            value={data.offered_amount}
                            onChange={onChangeData}
                            onBlur={() =>
                              validateOfferedAmount(data.offered_amount)
                            }
                          />
                        ) : (
                          <b>{data.offered_amount}</b>
                        )}
                        {errors.offered_amount && edit && (
                          <Typography className="error-text">
                            {errors.offered_amount}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Tenure</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit ? (
                          <div className="d-flex">
                            <TextField
                              name="offered_tenure"
                              value={data.offered_tenure}
                              onChange={onChangeData}
                              onBlur={() =>
                                validateOfferedTenure(data.offered_tenure)
                              }
                            />
                            <Select
                              name="offered_tenure_in"
                              onChange={onChangeData}
                              value={data.offered_tenure_in}
                              onBlur={() =>
                                validateOfferedTenureIn(data.offered_tenure_in)
                              }
                              style={{ width: "100px" }}
                            >
                              {["months", "years"].map((option, i) => (
                                <MenuItem key={i} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ) : (
                          <b>
                            {data.offered_tenure} {data.offered_tenure_in}
                          </b>
                        )}
                        {errors.offered_tenure && edit && (
                          <Typography className="error-text">
                            {errors.offered_tenure}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Interest</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit ? (
                          <TextField
                            name="offered_interest"
                            value={data.offered_interest}
                            onChange={onChangeData}
                            onBlur={() =>
                              validateOfferedInterest(data.offered_interest)
                            }
                          />
                        ) : (
                          <b>{data.offered_interest}</b>
                        )}
                        {errors.offered_interest && edit && (
                          <Typography className="error-text">
                            {errors.offered_interest}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                </Table>
              )}

              <Table>
                <>
                  {(((data.offered_amount > 10000 || data.req_amount > 10000) && data.loan_type === "Instant") ||
                    data.loan_type !== "Instant") && (
                    <>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>EMI</TableCell>
                        <TableCell style={{ width: "80%" }}>
                          {edit && !id ? (
                            <RadioGroup
                              row
                              aria-labelledby="emi"
                              name="emi"
                              value={data.emi}
                              onChange={onChangeData}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          ) : data.emi === 1 || data.emi === "1" ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                          {errors.emi && edit && (
                            <Typography className="error-text">
                              {errors.emi}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: "20%" }}>
                          EMI Amount
                        </TableCell>
                        <TableCell style={{ width: "80%" }}>
                          {<b>{data.emi_amount ? data.emi_amount : 0}</b>}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>
                      Processing Fee
                    </TableCell>
                    <TableCell style={{ width: "80%" }}>
                      {edit ? (
                        <TextField
                          name="processing_fee"
                          value={data.processing_fee}
                          onChange={onChangeData}
                          onBlur={() =>
                            validateProcessingFee(data.processing_fee)
                          }
                        />
                      ) : (
                        <b>{data.processing_fee ? data.processing_fee : "0"}</b>
                      )}
                      {errors.processing_fee && edit && (
                        <Typography className="error-text">
                          {errors.processing_fee}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>
                      Total Payable Amount
                    </TableCell>
                    <TableCell style={{ width: "80%" }}>
                      {edit &&
                      data.loan_type === "Instant" &&
                      data.offered_amount < 10000 ? (
                        <TextField
                          name="total_payable_amount"
                          value={data.total_payable_amount}
                          onChange={onChangeData}
                          onBlur={() =>
                            validateTotalPayable(
                              data.offered_amount,
                              data.total_payable_amount
                            )
                          }
                        />
                      ) : (
                        <b>
                          {data.total_payable_amount
                            ? data.total_payable_amount
                            : "0"}
                        </b>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              </Table>
            </Box>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          {id && edit && (
            <>
              <Button
                sx={{ mt: 3 }}
                onClick={addData}
                variant="contained"
                className="primary-btn me-3"
              >
                {" "}
                Update{" "}
              </Button>
              <Link className="text-decoration-none" to={`/loan?id=${id}`}>
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  className="primary-btn"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Link>
            </>
          )}
          {!id && edit && (
            <Button
              sx={{ mt: 3 }}
              onClick={addData}
              variant="contained"
              className="primary-btn"
            >
              {" "}
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
        />
      )}
    </>
  );
}
export default Loan;
