import Swal from 'sweetalert2'
import { AccordionDetails, Autocomplete, Button, Grid, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { identityDetails } from '../api/customerAuth'
import { getCustomer } from '../api/customer'
import { useEffect, useState } from "react";
import { getLocalStorage } from '../Common/cookie'
import { validateAadhar, validatePan, validateText } from '../Common/validations';
import { useNavigate } from 'react-router-dom';
import { getFileMimeType } from '../Common/fun';

function CustIdentity(props) {
    const navigate = useNavigate()
    const { adminData, id, edit } = props;
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [aadharDB, setAadharDB] = useState('')
    const [panDB, setPanDB] = useState('')
    const [selectedAadharFile, setSelectedAadharFile] = useState({ preview: "", raw: "" });
    const [selectedPANFile, setSelectedPANFile] = useState({ preview: "", raw: "" });

    // Function to handle file upload
    const handleAadharUpload = (event) => {
        const file = event.target.files[0];
        setSelectedAadharFile({
            preview: URL.createObjectURL(file),
            raw: file
        });
    };

    // Function to handle file upload
    const handlePANUpload = (event) => {
        const file = event.target.files[0];
        setSelectedPANFile({
            preview: URL.createObjectURL(file),
            raw: file
        });
    };

    function onChangeData(e) {
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getCustomer({
                    customer_id: id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setData(response.data);
                    setAadharDB(response.data?.doc.filter(obj => obj.doc_id.toLowerCase() === 'aadhar').pop())
                    setPanDB(response.data?.doc.filter(obj => obj.doc_id.toLowerCase() === 'pan').pop())
                }
            }
        };
        try {
            asyncFn();
        } catch (e) {
            navigate("/login")
        }
    }, [])

    function validatePanNo(str) {
        setErrors((errors) => ({
            ...errors,
            pan_no: validatePan(str).error,
        }));
    }
    function validateAadharNo(str) {
        setErrors((errors) => ({
            ...errors,
            aadhar_no: validateAadhar(str).error,
        }));
    }
    function validateAadharFile() {
        setErrors((errors) => ({
            ...errors,
            aadhar: validateText(selectedAadharFile?.preview ? selectedAadharFile?.preview : aadharDB).error,
        }));
    }
    function validatePanFile() {
        setErrors((errors) => ({
            ...errors,
            pan: validateText(selectedPANFile?.preview ? selectedPANFile?.preview : panDB).error,
        }));
    }

    function validateForm() {
        validatePanNo(data.pan_no)
        validateAadharNo(data.aadhar_no)
        validateAadharFile()
        validatePanFile()
        let valid = validatePan(data.pan_no).valid
            && validateAadhar(data.aadhar_no).valid
            && validateText(selectedAadharFile?.preview ? selectedAadharFile?.preview : aadharDB).valid
            && validateText(selectedPANFile?.preview ? selectedPANFile?.preview : panDB).valid;
        return valid;
    }

    async function saveData(e) {
        // e.preventDefault();
        if (validateForm()) {
            const formData = new FormData();
            formData.append("id", id);
            formData.append("pan_no", data.pan_no);
            formData.append("aadhar_no", data.aadhar_no);
            formData.append("pan", selectedPANFile.raw ? selectedPANFile.raw : null);
            formData.append("aadhar", selectedAadharFile.raw ? selectedAadharFile.raw : null);
            const response = await identityDetails({
                token: getLocalStorage('rv-admin')?.accessToken,
                data: formData
            });
            if (response?.status === 200) {
                Swal.fire(
                    'Good Job!',
                    'Customer data updated successfully',
                    'success'
                )
            }
        }
    }
    return (
        <>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Table>
                            <TableRow>
                                <TableCell style={{ width: "20%" }}>Aadhar No.</TableCell>
                                <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                    name="aadhar_no"
                                    value={data.aadhar_no}
                                    onChange={onChangeData}
                                    onBlur={() => validateAadharNo(data.aadhar_no)}
                                /> : data.aadhar_no}
                                    {errors.aadhar_no && edit && (
                                        <Typography className='error-text'>{errors.aadhar_no}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ width: "20%" }}>Aadhar PDF</TableCell>
                                <TableCell style={{ width: "80%" }}><div style={{ width: '150px', height: '150px', position: 'relative', overflow: 'hidden' }}>
                                    <div style={{ width: '100%', height: '100%', border: '1px solid #ccc', background: '#f9f9f9', textAlign: 'center', lineHeight: '150px' }}>
                                        {
                                            (() => {
                                                if (selectedAadharFile?.preview) {
                                                    return <embed src={selectedAadharFile.preview} style={{ width: "150px", height: "150px" }} type={getFileMimeType(selectedAadharFile.raw.name)} />
                                                } else if (aadharDB) {
                                                    return <embed src={`${process.env.REACT_APP_API}${aadharDB.path}`} style={{ width: "150px", height: "150px" }} type={getFileMimeType(aadharDB.path)} />
                                                } else {
                                                    return 'Not uploaded'
                                                }
                                            })()
                                        }
                                    </div>
                                    {edit && <input type="file" onChange={handleAadharUpload} style={{ position: 'absolute', top: 0, right: 0, opacity: 0, width: '100%', height: '100%', cursor: 'pointer' }} accept="application/pdf, image/*" />}
                                </div>
                                    {errors.aadhar && edit && (
                                        <Typography className='error-text'>{errors.aadhar}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ width: "20%" }}>PAN No.</TableCell>
                                <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                    name="pan_no"
                                    value={data.pan_no}
                                    onChange={onChangeData}
                                    onBlur={() => validatePanNo(data.pan_no)}
                                /> : data.pan_no}
                                    {errors.pan_no && edit && (
                                        <Typography className='error-text'>{errors.pan_no}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ width: "20%" }}>PAN PDF</TableCell>
                                <TableCell style={{ width: "80%" }}><div style={{ width: '150px', height: '150px', position: 'relative', overflow: 'hidden' }}>
                                    <div style={{ width: '100%', height: '100%', border: '1px solid #ccc', background: '#f9f9f9', textAlign: 'center', lineHeight: '150px' }}>
                                        {
                                            (() => {
                                                if (selectedPANFile?.preview) {
                                                    return <embed src={selectedPANFile.preview} style={{ width: "150px", height: "150px" }} type={getFileMimeType(selectedPANFile.raw.name)} />
                                                } else if (panDB) {
                                                    return <embed src={`${process.env.REACT_APP_API}${panDB.path}`} style={{ width: "150px", height: "150px" }} type={getFileMimeType(panDB.path)} />
                                                } else {
                                                    return 'Not uploaded'
                                                }
                                            })()
                                        }
                                    </div>
                                    {edit && <input type="file" onChange={handlePANUpload} style={{ position: 'absolute', top: 0, right: 0, opacity: 0, width: '100%', height: '100%', cursor: 'pointer' }} accept="application/pdf, image/*" />}
                                </div>
                                    {errors.pan && edit && (
                                        <Typography className='error-text'>{errors.pan}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        </Table>
                        {edit && <Button className='primary-btn' variant="contained" onClick={saveData} > Save</Button>}

                    </Grid>
                </Grid>
            </AccordionDetails>
        </>
    )
}
export default CustIdentity;