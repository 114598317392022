import CustomTable from "../Common/CustomTable";
import CancelIcon from "@mui/icons-material/Cancel";
import { loans } from "../api/loan";
import {
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLocalStorage } from "../Common/cookie";
import { getCustomerLoans } from "../api/customer";
import EditIcon from "@mui/icons-material/Edit";

function LoanData(props) {
  const { socket, adminData, customerId, setDownloadData, noEdit } = props;
  const navigate = useNavigate();

  const [search, setSearch] = useState({
    id: "",
    type: "",
    amount_min: "",
    amount_max: "",
    interest_min: "",
    interest_max: "",
    status: "",
  });
  const [data, setData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    socket?.on("loan", (newObject) => {
      setData((data) => {
        const filteredArray = data.filter((obj) => obj.id !== newObject.id);
        return [...filteredArray, newObject];
      });
    });
  }, [socket]);

  const getValue = (id, array) => {
    const value = array.find(data => data.id === id);
    return value.name;
  }

  useEffect(() => {
    let f_data = [];
    if (setDownloadData) {
      if (data.length > 0) {
        data.map((d) => {
          f_data.push({
            id: d.id,
            loan_type: getValue(d.loan_type, loanTypes),
            customer_id: d.customer.id,
            phone: d.customer.phone,
            name: d.customer.name,
            applied_on: new Date(d.created_at)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("-"),
            status: getValue(d.status, status),
          });
        });
      }
      setDownloadData(f_data);
    }
  }, [data]);

  const status = [
    {
      id: "",
      name: "All",
    },
    {
      id: "RequestedByClient",
      name: "Requested By Client",
    },
    {
      id: "Reviewed",
      name: "Reviewed",
    },
    {
      id: "CounterOffer",
      name: "Given Counter Offer",
    },
    {
      id: "ResponseByClient",
      name: "Responsed By Client",
    },
    {
      id: "CustomerKYC",
      name: "Customer KYC Data",
    },
    {
      id: "CustomerAddress",
      name: "Customer Address Data",
    },
    {
      id: "CustomerBank",
      name: "Customer Bank Data",
    },
    {
      id: "LoanReference",
      name: "Reference Data for Loan",
    },
    {
      id: "Granted",
      name: "Disbursal",
    },
    {
      id: "Cancelled",
      name: "Cancelled",
    },
  ];
  const bankNames = [
    {
      id: "",
      name: "All"
    },{
      id: "IndusindPersonal",
      name: "Indusind Personal Loan"
    },{
      id: "AdityaBirlaPersonal",
      name: "Aditya BirlaPersonal Loan"
    },{
      id: "SMFCPersonal",
      name: "SMFC Personal Loan"
    },{
      id: "CreditVidyaPersonal",
      name: "Credit Vidya Personal Loan"
    },{
      id: "FibePersonal",
      name: "Fibe Personal Loan"
    },{
      id: "IIFLBusiness",
      name: "IIFL Business Loan"
    },{
      id: "BajajPersonal",
      name: "Bajaj Personal Loan"
    },{
      id: "BirlaUdyogBusiness",
      name: "Aditya Birla Udyog Business Loan"
    }
  ]
  const loanTypes = [
    {
      id: "",
      name: "All",
    },
    // {
    //   id: "Instant",
    //   name: "Instant",
    // },
    {
      id: "InstantFromBank",
      name: "Instant Loan From Bank",
    },
    {
      id: "Business",
      name: "Business Loan",
    },
    {
      id: "Education",
      name: "Education Loan",
    },
    {
      id: "Machinary",
      name: "Machinary Loan",
    },
    {
      id: "Home",
      name: "Home Laon",
    },
    {
      id: "AgainstProperty",
      name: "Loan Against Property",
    },
    {
      id: "Professional",
      name: "Professional Loan",
    },
    {
      id: "Car",
      name: "Car Loan",
    },
    {
      id: "Student",
      name: "Student Loan",
    },
  ];

  useEffect(() => {
    getAllLoans("");
  }, []);

  const getAllLoans = async (search) => {
    let response;
    if (customerId) {
      response = await getCustomerLoans({
        customer_id: customerId,
        token:
          adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
      });
    } else {
      response = await loans({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin").accessToken,
      });
    }
    if (response?.status === 200) {
      let filtered = response.data;
      if (search.id) {
        filtered = filtered.filter((d) => {
          return String(d.id).match(`.*${search.id}.*`);
        });
      }
      if (search.type) {
        filtered = filtered.filter((d) => {
          return d.loan_type.match(`.*${search.type}.*`);
        });
      }
      if (search.bank_name) {
        filtered = filtered.filter((d) => {
          return d.bank_name.match(`.*${search.bank_name}.*`);
        });
      }
      if (search.status) {
        filtered = filtered.filter((d) => {
          return d.status.match(`.*${search.status}.*`);
        });
      }
      if (search.amount_min) {
        filtered = filtered.filter((d) => {
          let a = d.offered_amount ? d.offered_amount : d.req_amount;
          return Number(a) >= Number(search.amount_min);
        });
      }
      if (search.amount_max) {
        filtered = filtered.filter((d) => {
          let a = d.offered_amount ? d.offered_amount : d.req_amount;
          return Number(a) <= Number(search.amount_max);
        });
      }
      if (search.interest_min) {
        filtered = filtered.filter((d) => {
          let a = d.offered_interest ? d.offered_interest : d.req_interest;
          return Number(a) >= Number(search.interest_min);
        });
      }
      if (search.interest_max) {
        filtered = filtered.filter((d) => {
          let a = d.offered_interest ? d.offered_interest : d.req_interest;
          return Number(a) <= Number(search.interest_max);
        });
      }
      setData(filtered);
    }
  };

  useEffect(() => {
    getAllLoans(search);
  }, [search]);

  const viewLoanData = (data) => {
    navigate("/loan?id=" + data.id);
  };

  const editLoanData = (data) => {
    navigate("/loan?id=" + data.id + "&edit=1");
  };

  const createRows = (data) => {
    const headCells = [
      {
        id: "id",
        label: "Id",
      },
      {
        id: "loan_type",
        label: "Type",
      },
      {
        id: "amount",
        label: "Amount",
      },
      {
        id: "interest",
        label: "Interest",
      },
      {
        id: "tenure",
        label: "Tenure",
      },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "edit",
        label: "",
        type: "button",
        noSort: true,
        icon: EditIcon,
        iconStyle: { color: "blue" },
        noDisplay: noEdit,
        callback: editLoanData,
      },
      {
        id: "delete_all",
        noDisplay: true,
      },
    ];
    function createData(
      id,
      loan_type,
      amount,
      interest,
      tenure,
      status,
      read_time,
      link
    ) {
      return {
        id,
        loan_type,
        amount,
        interest,
        tenure,
        status,
        read_time,
        link,
      };
    }

    const rows = data.map((value) => {
      return createData(
        value.id,
        value.loan_type === "InstantFromBank" ? getValue(value.loan_type, loanTypes) + "\n from " + getValue(value.bank_name, bankNames) : getValue(value.loan_type, loanTypes),
        value.offered_amount ? (
          value.offered_amount
        ) : (
          <div style={{ color: "red", textAlign: "right" }}>
            {" "}
            {value.req_amount}{" "}
          </div>
        ),
        value.offered_interest ? (
          value.offered_interest
        ) : (
          <div style={{ color: "red", textAlign: "right" }}>
            {" "}
            {value.req_interest}{" "}
          </div>
        ),
        value.offered_tenure ? (
          `${value.offered_tenure} ${value.offered_tenure_in}`
        ) : (
          <div style={{ color: "red" }}>
            {" "}
            {value.req_tenure} {value.req_tenure_in}{" "}
          </div>
        ),
        getValue(value.status, status),
        value.read_time,
        noEdit ? "" : `/loan?id=${value.id}`
      );
    });

    const filters = (
      <>
        <TableRow hover tabIndex={-1}>
          <TableCell>
            {clear ? (
              <Tooltip title="Clear Filters" arrow>
                <IconButton onClick={clearFilter}>
                  <CancelIcon className="primary-text" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell style={{ maxWidth: "50px" }}>
            <TextField
              variant="outlined"
              name="id"
              value={search.id}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.type}
              name="type"
              onChange={onChangeData}
            >
              {loanTypes.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {search.type === "InstantFromBank" && (
              <>
                <Select
                  MenuProps={{ autoFocus: false }}
                  value={search.bank_name}
                  name="bank_name"
                  onChange={onChangeData}
                >
                  {bankNames.map((option, i) => (
                    <MenuItem key={i} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </TableCell>
          <TableCell style={{ width: "160px" }}>
            <TextField
              label="Min"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="amount_min"
              value={search.amount_min}
              onChange={onChangeData}
            />
            <TextField
              label="Max"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="amount_max"
              value={search.amount_max}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell style={{ width: "160px" }}>
            <TextField
              label="Min"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="interest_min"
              value={search.interest_min}
              onChange={onChangeData}
            />
            <TextField
              label="Max"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%", maxWidth: "75px" }}
              variant="outlined"
              name="interest_max"
              value={search.interest_max}
              onChange={onChangeData}
            />
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Select
              MenuProps={{ autoFocus: false }}
              value={search.status}
              name="status"
              onChange={onChangeData}
            >
              {status.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          {!noEdit && <TableCell style={{ width: "34px" }}></TableCell>}
        </TableRow>
      </>
    );

    return { headCells, rows, filters };
  };

  function clearFilter() {
    setSearch({
      id: "",
      type: "",
      amount_min: "",
      amount_max: "",
      interest_min: "",
      interest_max: "",
      status: "",
    });
  }

  function onChangeData(e) {
    setSearch((search) => ({
      ...search,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    let flag = 0;
    Object.keys(search).forEach(function (key) {
      if (search[key] !== "") {
        flag = 1;
      }
    });
    flag === 0 ? setClear(false) : setClear(true);
  }, [search]);

  return (
    <Box component={Paper} sx={{ pt: 2, ml: noEdit ? 2 : 0, width: "100%" }}>
      <CustomTable
        {...createRows(data)}
        hideDelete={true}
        checkHighlight={true}
      />
    </Box>
  );
}
export default LoanData;
